import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {setOrganisations} from '../actions/common.actions';
import {CONFIG7_INIT} from '../../../ngrx/common/module-initialization.actions';
import {CommonService} from '../../services/common.service';
import {OrganisationData} from '../../models/organisation-data.model';
import {map as _map} from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class CommonEffects {

  constructor(private actions$: Actions,
              private store: Store,
              private commonService: CommonService) {
  }

  loadOrganisations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(CONFIG7_INIT),
    switchMap(() => this.commonService.getOrganisations()),
    map((organisations: OrganisationData[]) => _map(organisations, (organisation: OrganisationData) => organisation.organisationId)),
    map((organisations: string[]) => setOrganisations({organisations}))
  ));
}
