import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Dashboard, DashboardsService} from '../../core/services/dashboards.service';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {UserOrganisationService} from '../../core/organisation/user-organisation.service';
import {BrowserTabService} from '../../shared/browser-tab/browser-tab.service';
import {DevVersionService} from '../../core/dev-versions/dev-version.service';
import {combineLatest, Observable, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {getBeDevVersion, getDevVersionData, getFeDevVersion, getUseDevEnvironment} from '../../ngrx/dev-versions/dev-version.selectors';
import {DevVersionData} from '../../core/dev-versions/dev-version-data';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserRole} from '../../core/authentication/user-role';

@Component({
  selector: 'app-base-app',
  templateUrl: './base-app.component.html',
  styleUrls: ['./base-app.component.scss']
})
export class BaseAppComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  usernameOrEmail: string;
  isRealmManager: boolean;
  dashboards: Dashboard[];
  // Dev versions
  devVersionData$: Observable<DevVersionData>;
  selectedBeDevVersion$: Observable<string>;
  selectedFeDevVersion$: Observable<string>;
  useDevEnvironment$: Observable<boolean>;
  isBeTeam: boolean;

  constructor(private authenticationService: AuthenticationService,
              private dashboardsService: DashboardsService,
              public loadingBarService: LoadingBarService,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private userOrganisationService: UserOrganisationService,
              private browserTabService: BrowserTabService,
              private devVersionService: DevVersionService,
              private matSnackbar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.initDevVersions();
    this.isRealmManager = this.authenticationService.hasAccessToRealmManagement();
    this.usernameOrEmail = this.authenticationService.getEmailOrUsername();
    this.dashboards = this.dashboardsService.getDashboards();
    this.subscribeToRouterEventsForGoogleAnalytics();
    this.browserTabService.subscribeToRouteEventsForTabNames();
  }

  private initDevVersions(): void {
    this.selectedBeDevVersion$ = this.store.select(getBeDevVersion);
    this.selectedFeDevVersion$ = this.store.select(getFeDevVersion);
    // Get all dev versions from BE replace selected with prod if it is not deployed
    this.devVersionData$ = combineLatest([this.store.select(getDevVersionData), this.selectedBeDevVersion$]).pipe(
      filter(([devVersionData, selectedBeDevVersion]: [DevVersionData, string]) => devVersionData != null && selectedBeDevVersion != null),
      map(([devVersionData, _]: [DevVersionData, string]) => devVersionData)
    );
    this.useDevEnvironment$ = this.store.select(getUseDevEnvironment);
  }

  setSelectedBeDevVersion(beDevVersion: string): void {
    this.devVersionService.navigateToBeVersion(beDevVersion);
  }

  setSelectedFeDevVersion(feDevVersion: string): void {
    this.devVersionService.navigateToFeVersion(feDevVersion);
  }

  setSelectedEnvironment(useDevEnvironment: boolean): void {
    this.devVersionService.navigateToEnvironment(useDevEnvironment);
  }

  isExternalMediationPartner(): boolean {
    return this.authenticationService.hasRole(UserRole.MEDIATION_EXTERNAL);
  }

  logout(): void {
    this.authenticationService.logout();
  }

  private subscribeToRouterEventsForGoogleAnalytics(): void {
    // Google analytics does not support SPA, so we need to call it manually on every page navigation
    this.router.events.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
