import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WINDOW} from '../providers/window-provider';
import {UserOrganisationService} from '../organisation/user-organisation.service';
import {ActivatedRoute} from '@angular/router';
import {DevVersionService} from '../dev-versions/dev-version.service';
import {State} from '@ngrx/store';

class DispatchEntry {
  moduleName: string;
  uriPrefix: string;
  localhostPort: number;
}

const BASE_DEV_ENVIRONMENT_URL: string = 'https://o7apps-dev.outfit7.net';
const BASE_BACKEND_URL: string = 'https://apps.outfit7.com';
const BASE_O7PO_BACKEND_URL: string = 'https://po-stpceyl2ua-uc.a.run.app';
const BASE_O7PC_BACKEND_URL: string = 'https://pc-stpceyl2ua-uc.a.run.app';
const CHINA_PROXY_BACKEND_URL: string = 'https://apps2-hk.outfit7.com';

const DISPATCH_ENTRIES: DispatchEntry[] = [
  {
    moduleName: 'processing',
    uriPrefix: '/rest/processing/',
    localhostPort: 8081
  },
  {
    uriPrefix: '/rest/ad/',
    localhostPort: 8082,
    moduleName: 'crosspromo'
  },
  {
    uriPrefix: '/rest/visual7/',
    localhostPort: 8089,
    moduleName: 'visual7-backend'
  },
  {
    uriPrefix: '/rest/admin/',
    localhostPort: 8092,
    moduleName: 'admin'
  },
  {
    uriPrefix: '/rest/apps/',
    localhostPort: 8091,
    moduleName: 'apps'
  },
  {
    uriPrefix: '/rest/ads/providers/',
    localhostPort: 8097,
    moduleName: 'ads'
  },
  {
    uriPrefix: '/rest/talkingFriends/v1/vca/',
    localhostPort: 8080,
    moduleName: 'default'
  },
  {
    uriPrefix: '/rest/talkingFriends/',
    localhostPort: 8096,
    moduleName: 'grid'
  },
  {
    uriPrefix: '/rest/compliance/',
    localhostPort: 8097,
    moduleName: 'compliance'
  },
  {
    uriPrefix: '',
    localhostPort: 8080,
    moduleName: 'default'
  }
];

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(@Inject(WINDOW) private window: Window,
              private route: ActivatedRoute,
              private userOrganisationService: UserOrganisationService,
              // Store state enables to return values directly from the store without using observables
              private state: State<any>
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip converting absolute urls
    if (/^https?:\/\//i.test(req.url)) {
      return next.handle(req);
    } else {
      return next.handle(req.clone({
        url: this.getDispatchUrl(req.url, this.state.getValue().dashboardUser.userOrganisation),
        params: req.params
      }));
    }
  }

  private getDispatchUrl(url: string, selectedOrganisationId: string): string {

    if (url.endsWith('.html')) {
      return url;
    } else if (url.startsWith('/placement/optimization')) {
      return this.getPlacementOptimizationServiceBackendUrl(url);
    } else if (url.startsWith('/placement/configuration')) {
      return this.getPlacementConfigurationServiceBackendUrl(url);
    } else {
      return this.getBaseBackendUrl(url, selectedOrganisationId);
    }
  }

  private getPlacementOptimizationServiceBackendUrl(url: string): string {
    return BASE_O7PO_BACKEND_URL + url;
  }

  private getPlacementConfigurationServiceBackendUrl(url: string): string {
    return BASE_O7PC_BACKEND_URL + url;
  }

  private getBaseBackendUrl(url: string, selectedOrganisationId: string): string {
    const selectedBeDevVersion: string = this.state.getValue().applicationState.beDevVersion;
    const useDevEnvironment: string = this.state.getValue().applicationState.useDevEnvironment;

    if (selectedBeDevVersion && selectedBeDevVersion != DevVersionService.PROD_VERSION) {
      // Dev version is selected
      let devVersionsUrlPart: string = 'https://' + selectedBeDevVersion + '.';
      let environmentUrlPart: string = useDevEnvironment ? 'o7apps-dev.outfit7.net' : 'apps.outfit7.com';
      return devVersionsUrlPart + environmentUrlPart + url;
    } else {
      // Use prod version
      if (!useDevEnvironment) {
        // Determine url based on organisation
        if (selectedOrganisationId == UserOrganisationService.JINKE_ORGANISATION_ID) {
          return CHINA_PROXY_BACKEND_URL + url;
        }
        return BASE_BACKEND_URL + url;
      } else {
        // Use dev environment on production version backend
        return BASE_DEV_ENVIRONMENT_URL + url;
      }
    }

  }

  private getMatchingDispatchEntry(relativeUrl: string): DispatchEntry {
    const dispatchEntry: DispatchEntry = DISPATCH_ENTRIES.find((entry) => relativeUrl.startsWith(entry.uriPrefix));
    return dispatchEntry ? dispatchEntry : DISPATCH_ENTRIES[DISPATCH_ENTRIES.length - 1];
  }

}
