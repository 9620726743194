import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './components/dialogs/confirm-dialog/confirm-dialog.component';
import {DashboardMenuItemComponent} from './components/dashboard-menu-item/dashboard-menu-item.component';
import {MaterialAndFlexModule} from './modules/material-and-flex.module';
import {RouterModule} from '@angular/router';
import {MomentModule} from 'ngx-moment';
import {ToolbarActionButtonComponent} from './components/page-elements/toolbar-action-button/toolbar-action-button.component';
import {SelectSearchComponent} from './components/select-search/select-search.component';
import {
  WhiteAndBlackListFilterFormComponent
} from './filters/components/forms/white-and-black-list-filter-form/white-and-black-list-filter-form.component';
import {RangeFilterFormComponent} from './filters/components/forms/range-filter-form/range-filter-form.component';
import {BasicFilterFormComponent} from './filters/components/forms/basic-filter-form/basic-filter-form.component';
import {NullToNonePipe} from './pipes/null-to-none.pipe';
import {PreviewFilterPipe} from './filters/pipes/preview-filter.pipe';
import {SelectCheckAllComponent} from './components/select-check-all/select-check-all.component';
import {InformationDialogComponent} from './components/dialogs/information-dialog/information-dialog.component';
import {HIGHLIGHT_OPTIONS, HighlightModule} from 'ngx-highlightjs';
import {ScoreboardComponent} from './components/page-elements/scoreboard/scoreboard.component';
import {AppClassNiceNamePipe} from './app/pipes/app-nice-name.pipe';
import {PageTitleSeparatorComponent} from './components/page-elements/page-title-separator/page-title-separator.component';
import {SimpleDescriptionComponent} from './components/page-elements/simple-description/simple-description.component';
import {CenterContentComponent} from './components/page-elements/center-content/center-content.component';
import {SelectO7deviceComponent} from './components/select-o7device/select-o7device.component';
import {FilterSelectionComponent} from './filters/components/filterableV2-components/filter-selection/filter-selection.component';
import {DiffViewComponent} from './components/diff-view/diff-view.component';
import {
  PreviewHistoryChangesDialogComponent
} from './components/dialogs/preview-history-changes-dialog/preview-history-changes-dialog.component';
import {FilterRowsComponent} from './filters/components/filterableV2-components/filter-rows/filter-rows.component';
import {SimpleOrJsonPipe} from './pipes/simple-or-json.pipe';
import {ReactiveComponentModule} from '@ngrx/component';
import {QueryParamService} from './services/query-param.service';
import {FilterDialogComponent} from './filters/components/filter-dialog/filter-dialog.component';
import {FiltersPanelComponent} from './filters/components/filters-panel/filters-panel.component';
import {GenericSidenavComponent} from './components/generic-sidenav/generic-sidenav.component';
import {SidenavWithToolbarButtonComponent} from './components/sidenav-with-toolbar-button/sidenav-with-toolbar-button.component';
import {GetFilterForId} from './filters/components/filterableV2-components/filter-rows/get-filter-for-id.pipe';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CommonEffects} from './ngrx/effects/common.effects';
import {commonReducer} from './ngrx/reducers/common.reducer';
import {NgrxMatSelectViewAdapter} from './ngrx/view-adapters/ngrx-mat-select-view-adapter';
import {ChipsInputComponent} from './components/chips-input/chips-input.component';
import {ArrayValuesToTextPipe} from './pipes/array-values-to-text.pipe';
import {WarningMessageComponent} from './components/warning-message/warning-message.component';
import {SuccessMessageComponent} from './components/success-message/success-message.component';
import { ContainsHtmlDirective } from './directives/contains-html.directive';
import {SlackModule} from '../modules/slack/slack.module';

import { O7SelectComponent } from './components/o7-select/o7-select.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialAndFlexModule,
    RouterModule,
    MomentModule,
    HighlightModule,
    StoreModule.forFeature('commonStateFeature', commonReducer),
    EffectsModule.forFeature([CommonEffects]),
    SlackModule
  ],
  declarations: [
    ConfirmDialogComponent,
    InformationDialogComponent,
    DashboardMenuItemComponent,
    ToolbarActionButtonComponent,
    FiltersPanelComponent,
    SelectSearchComponent,
    NgrxMatSelectViewAdapter,
    WhiteAndBlackListFilterFormComponent,
    RangeFilterFormComponent,
    BasicFilterFormComponent,
    NullToNonePipe,
    PreviewFilterPipe,
    SelectCheckAllComponent,
    ScoreboardComponent,
    AppClassNiceNamePipe,
    PageTitleSeparatorComponent,
    SimpleDescriptionComponent,
    CenterContentComponent,
    SelectO7deviceComponent,
    FilterSelectionComponent,
    FilterRowsComponent,
    SelectO7deviceComponent,
    DiffViewComponent,
    PreviewHistoryChangesDialogComponent,
    SimpleOrJsonPipe,
    FilterDialogComponent,
    GenericSidenavComponent,
    GetFilterForId,
    SidenavWithToolbarButtonComponent,
    ChipsInputComponent,
    ArrayValuesToTextPipe,
    WarningMessageComponent,
    SuccessMessageComponent,
    O7SelectComponent,
    ContainsHtmlDirective
  ],
  exports: [
    CommonModule,
    ReactiveComponentModule,
    MaterialAndFlexModule,
    ConfirmDialogComponent,
    InformationDialogComponent,
    DashboardMenuItemComponent,
    MomentModule,
    ToolbarActionButtonComponent,
    FiltersPanelComponent,
    SelectSearchComponent,
    NgrxMatSelectViewAdapter,
    WhiteAndBlackListFilterFormComponent,
    RangeFilterFormComponent,
    BasicFilterFormComponent,
    NullToNonePipe,
    PreviewFilterPipe,
    SelectCheckAllComponent,
    ScoreboardComponent,
    AppClassNiceNamePipe,
    PageTitleSeparatorComponent,
    SimpleDescriptionComponent,
    CenterContentComponent,
    SelectO7deviceComponent,
    FilterSelectionComponent,
    FilterRowsComponent,
    DiffViewComponent,
    SimpleOrJsonPipe,
    GenericSidenavComponent,
    GetFilterForId,
    SidenavWithToolbarButtonComponent,
    ChipsInputComponent,
    ArrayValuesToTextPipe,
    WarningMessageComponent,
    SuccessMessageComponent,
    O7SelectComponent,
    ContainsHtmlDirective
  ],
  providers: [
    QueryParamService,
    {
      // HIGHLIGHT_OPTIONS must be declared in shared/app module
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        }
      }
    }
  ]
})
export class SharedModule {
}
