export const CONFIG7_INIT: string = 'CONFIG7 INITIALIZED';
export const LOCALIZATOR_INIT: string = 'LOCALIZATOR INITIALIZED';
export const EVENTS_HORIZON_INIT: string = 'EVENTS HORIZON INITIALIZED';
export const TOOLS_INIT: string = 'TOOLS INITIALIZED';
export const APP_USER_STATE_TEMPLATES_INIT: string = 'APP USER STATE TEMPLATES INITIALIZED';
export const BOOK_OF_RECORDS_INIT: string = 'BOOK OF RECORDS INIT';
export const COUPONS_INIT: string = 'COUPONS INIT';
export const PLACEMENTS_INIT: string = 'PLACEMENTS INITIALIZED';
export const MEDIATION_INIT: string = 'MEDIATION INITIALIZED';
export const MEDIATION_EXT_INIT: string = 'MEDIATION EXT INITIALIZED';
export const KEYCLOAK_INIT: string = 'KEYCLOAK INITIALIZED';
export const BE_VERSION_VERIFIED: string = 'BE VERSION VERIFIED';
