import {createAction, props} from '@ngrx/store';
import {DevVersionData} from '../../core/dev-versions/dev-version-data';

export const setDevVersionData: any = createAction(
  '[Set] Set Dev Version Data',
  props<{ devVersionData: DevVersionData }>()
);

export const setBeDevVersion: any = createAction(
  '[Set] Set Be Dev Version',
  props<{ beDevVersion: string }>()
);

export const setFeDevVersion: any = createAction(
  '[Set] Set Fe Dev Version',
  props<{ feDevVersion: string }>()
);

export const setUseDevEnvironment: any = createAction(
  '[Set] Set Use Dev Environment',
  props<{ useDevEnvironment: boolean }>()
);
