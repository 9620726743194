import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {OrganisationData} from '../models/organisation-data.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private readonly BASE_URL: string = '/rest/visual7/common';

  constructor(private http: HttpClient) {
  }

  getOrganisations(): Observable<OrganisationData[]> {
    return this.http.get<OrganisationData[]>(`${this.BASE_URL}/organisations`);
  }
}
