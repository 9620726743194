export class UserRole {
  public static SWAGGER_UI: string = 'ROLE_SWAGGER_UI';
  public static VISUAL7_INDEX: string = 'ROLE_VISUAL7_INDEX';
  public static O7DEVICES_INTERNAL: string = 'ROLE_O7DEVICES_INTERNAL';
  public static O7DEVICES_EXTERNAL: string = 'ROLE_O7DEVICES_EXTERNAL';
  public static O7DEVICES_ADMIN: string = 'ROLE_O7DEVICES_ADMIN';
  public static O7DEVICES_TESTER: string = 'ROLE_O7DEVICES_TESTER';
  public static GDOC_VIEWER: string = 'ROLE_GDOC_VIEWER';
  public static GDOC_EDITOR: string = 'ROLE_GDOC_EDITOR';
  public static MEDIATION_VIEWER: string = 'ROLE_MEDIATION_VIEWER';
  public static MEDIATION_ADOPS: string = 'ROLE_MEDIATION_ADOPS';
  public static MEDIATION_ADMIN: string = 'ROLE_MEDIATION_ADMIN';
  public static MEDIATION_EXTERNAL: string = 'ROLE_MEDIATION_EXTERNAL';
  public static USER_SUPPORT: string = 'ROLE_USER_SUPPORT';
  public static UNKNOWN_APPS: string = 'ROLE_UNKNOWN_APPS';
  public static COUPONS: string = 'ROLE_COUPONS';
  public static PUNISHER: string = 'ROLE_PUNISHER';
  public static NEWS: string = 'ROLE_NEWS';
  public static AD_CAMPAIGNS: string = 'ROLE_ADCAMPAIGNS';
  public static AB_TESTS_VIEWER: string = 'ROLE_AB_TESTS_VIEWER';
  public static AB_TESTS_EDITOR: string = 'ROLE_AB_TESTS_EDITOR';
  public static AB_TESTS_LIMITED_EDITOR: string = 'ROLE_AB_TESTS_LIMITED_EDITOR';
  public static STARLITE_SYMBOLICATOR: string = 'ROLE_STARLITE_SYMBOLICATOR';
  public static GRID_BUILDER: string = 'ROLE_GRID_BUILDER';
  public static APP_USER_STATE_TEMPLATES: string = 'ROLE_APP_USER_STATE_TEMPLATES';
  // Config7 roles
  public static CONFIG7_VIEWER: string = 'ROLE_CONFIG7_VIEWER';
  public static CONFIG7_EDITOR: string = 'ROLE_CONFIG7_EDITOR';
  public static CONFIG7_ADMIN: string = 'ROLE_CONFIG7_ADMIN';
  public static CONFIG7_EDITORS: string[] = [
    UserRole.CONFIG7_EDITOR,
    UserRole.CONFIG7_ADMIN
  ];
  public static CONFIG7_ALL_ROLES: string[] = UserRole.CONFIG7_EDITORS.concat(UserRole.CONFIG7_VIEWER);
  // Localizator dashboard roles
  public static LOCALIZATOR_VIEWER: string = 'ROLE_LOCALIZATOR_VIEWER';
  public static LOCALIZATOR_QA: string = 'ROLE_LOCALIZATOR_QA';
  public static LOCALIZATOR_EDITOR_PRODUCT: string = 'ROLE_LOCALIZATOR_EDITOR_PRODUCT';
  public static LOCALIZATOR_EDITOR_GAME_DEVELOPER: string = 'ROLE_LOCALIZATOR_EDITOR_GAME_DEVELOPER';
  public static LOCALIZATOR_EDITOR_GAME_WRITER: string = 'ROLE_LOCALIZATOR_EDITOR_GAME_WRITER';
  public static LOCALIZATOR_EDITOR_L10N_TEAM: string = 'ROLE_LOCALIZATOR_EDITOR_L10N_TEAM';
  public static LOCALIZATOR_ADMIN: string = 'ROLE_LOCALIZATOR_ADMIN';
  public static LOCALIZATOR_EDITORS: string[] = [
    UserRole.LOCALIZATOR_EDITOR_PRODUCT,
    UserRole.LOCALIZATOR_EDITOR_GAME_DEVELOPER,
    UserRole.LOCALIZATOR_EDITOR_GAME_WRITER,
    UserRole.LOCALIZATOR_EDITOR_L10N_TEAM,
    UserRole.LOCALIZATOR_ADMIN
  ];
  public static LOCALIZATOR_ALL_ROLES: string[] =
    UserRole.LOCALIZATOR_EDITORS.concat([UserRole.LOCALIZATOR_VIEWER, UserRole.LOCALIZATOR_QA]);
  public static EVENTS_AS_CODE_EDITOR: string = 'ROLE_EVENTS_AS_CODE_EDITOR';
  public static EVENTS_AS_CODE_VIEWER: string = 'ROLE_EVENTS_AS_CODE_VIEWER';
  public static EVENTS_AS_CODE_ALL_ROLES: string[] = [UserRole.EVENTS_AS_CODE_VIEWER, UserRole.EVENTS_AS_CODE_EDITOR];
  // Gladiators
  public static GLADIATORS_RANKINGS: string = 'ROLE_GLADIATORS_RANKINGS_VIEWER';
  // AppRocket
  public static APP_ROCKET_VIEWER: string = 'ROLE_APPROCKET_VIEWER';
  public static APP_ROCKET_EDITOR: string = 'ROLE_APPROCKET_EDITOR';
}
